import { defineStore } from 'pinia'
import type { AccountsForMapping, OrganizationChartOfAccounts, OrganizationChartOfAccountsUpdate } from '~/repository/modules'

export const useAccountsStore = defineStore('accounts', () => {
  const { $api, $toast } = useNuxtApp()

  const accounts = ref<OrganizationChartOfAccounts[]>([])
  const accountsForMapping = ref<AccountsForMapping>()
  const loading = ref(false)
  const saving = ref(false)

  const list = async () => {
    loading.value = true
    return $api.accounts
      .list()
      .then((response) => {
        accounts.value = response.data
      })
      .finally(() => (loading.value = false))
  }

  const listForMapping = async () => {
    loading.value = true
    return $api.accounts
      .listForMapping()
      .then((response) => {
        response.data.gl_accounts = response.data.gl_accounts.sort((a, b) => a.account < b.account ? -1 : 1)
        response.data.organization_chart_of_accounts = response.data.organization_chart_of_accounts.sort((a, b) => (a.description ?? '').localeCompare(b.description!))

        accountsForMapping.value = response.data
      })
      .finally(() => (loading.value = false))
  }

  const updateMapping = async (data: OrganizationChartOfAccountsUpdate) => {
    saving.value = true
    return $api.accounts
      .updateMapping(data)
      .then((response) => {
        $toast.addToast({
          title: 'Success',
          message: response.data.message,
          type: 'success',
        })

        // refresh the list
        listForMapping()
      })
      .finally(() => (saving.value = false))
  }

  return {
    // state
    accounts,
    accountsForMapping,
    loading,
    saving,

    // functions
    list,
    listForMapping,
    updateMapping,
  }
})
